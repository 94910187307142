<template>
    <div class="t-i-box">
        <div class="t-title-text">
            <div class="left-t">
                第{{item.t_order*1 + 1}}题： ({{item.type|getType}})
            </div>
            <div class="right-t">
                <div class="any-t" :class="form.analysis?'focus':''" @click="drawer=true">
                    <i class="el-icon-bell"></i> 解析
                </div>
                <div class="del-t" @click="delTestItem">
                    <i class="el-icon-delete"></i> 删除
                </div>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="标题" required>
                    <el-input v-model="form.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item v-if="form.type!='FILLIN'">
                    <div class="selectBox" v-for="(it,index) in form.answerList" :key="index">
                        <div class="icon" title="点击确认答案" @click="isAnswer(index)">
                            <van-icon :name="form.type=='checkbox'?'stop-circle-o':'circle'" :color="it.isAnswer?'#39f':'#333'"/>
                        </div>
                        <div class="input">
                            <el-input v-if="it.type=='text'" v-model="it.name" placeholder="请输入选项"></el-input>
                            <el-image
                            v-if="it.type=='image'"
                            style="width: 90px; height: 50px"
                            :preview-src-list="imgList"
                            :src="it.name"></el-image>
                        </div>
                        <div class="menu">
                            <el-dropdown @command="chooseType">
                                <span class="el-dropdown-link">
                                    {{it.type=='text'?'文本选项':'图片选项'}}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{type:it.value,index:index}" v-for="(it,i) in typeList" :key='i'>{{it.name}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div class="m-item" v-if="it.type=='image'">
                                <el-upload
                                    v-loading="loading"
                                    class="avatar-uploader"
                                    :on-success="uploadSuccess"
                                    :data="jsonData"
                                    :action="actionUrl"
                                    :show-file-list="false">
                                    <i class="el-icon-upload2 " title="上传图片" @click="clickUpload(index)"></i>
                                </el-upload>
                            </div>
                            <div class="m-item" title="添加选项" @click="addSelect(index)">
                                <van-icon name="plus" />
                            </div>
                            <div class="m-item" title="删除选项" @click="delSelect(index)">
                                <van-icon name="minus" />
                            </div>
                        </div>
                        <div class="isanswer">
                            <el-tag size="mini" v-if="it.isAnswer">答案</el-tag>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item v-else label="答案" required>
                    <div class="m-fillin demo-input-suffix">
                        <el-input
                            placeholder="请输入填空答案"
                            suffix-icon="el-icon-edit-outline"
                            v-model="form.answer">
                        </el-input>
                    </div>
                </el-form-item>
        </el-form>
        <!-- editor -->
         <el-dialog title="编辑解析"  append-to-body
        :visible.sync="drawer">
            <vue-ueditor-wrap v-model="form.analysis" :config="myConfig"></vue-ueditor-wrap>
            <div class="btn-box">
                 <el-button type="success" @click="drawer=false" plain>
                    确定</el-button> 
            </div>
        </el-dialog>
    </div>
</template>
<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
    components:{
        VueUeditorWrap
    },
    props:{
        index:{
            type:Number
        },
        item:{
            type:Object
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = val
            },
            immediate:true,
            deep:true
        }
    },
    filters:{
        getType(type){
            let str = '单选题'
            switch(type){
                case "checkbox":
                str = '多选题'
                break;
                case "FILLIN":
                str = '填空题'
                break;
            }
            return str
        }
    },
    data(){
        return{
            imgList:[],
            current:0,//当前上传图片是哪个选项
            loading:false,
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            typeList:[
                {
                    name:"文本选项",
                    value:"text"
                },{
                    name:"图片选项",
                    value:"image"
                }
            ],
            form:{
                title:"",
                type:"",
                analysis:"",
                answer:"",
                data:"",
                answerList:[{
                    name:"",
                    type:"",
                    isAnswer:false,
                    checked:false
                }],
                t_order:""
            },
            user:JSON.parse(sessionStorage.getItem('user')),
            drawer:false,
            myConfig: {
                // 编辑器不自动被内容撑高
                serverparam:{
                    method:"ueditorUpload"
                },
                toolbars: [
                [
                    "fullscreen",
                    "source",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "justifyleft",
                    "justifycenter",
                    "justifyright",
                    "justifyjustify",
                    "|",
                    "|",
                    "bold",
                    "italic",
                    "simpleupload"
                ]
                ],
                autoHeightEnabled: false,
                maximumWords:500,
                // 初始容器高度
                initialFrameHeight: 200,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: "",
                baseUrl:"",
                imageActionName:"",
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/'
            }
        }
    },
    created(){
        this.myConfig.serverUrl=this.API.api+'?token='+this.user.token
        this.myConfig.baseUrl=this.API.apiUrl
        console.log(this.myConfig.baseUrl) 
        this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods:{
        //上传图片
        clickUpload(index){
            //console.log(index)
            this.current = index
        },
        //图片上传成功
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
                this.form.answerList[this.current].name = this.API.api+response.data
                this.imgList.push(this.API.api+response.data)
            }else{
                this.$message({
                    message:response.msg,
                    type: 'warning'
                });
            }
        },
        //选择类型
        chooseType(e){
            this.form.answerList[e.index].type = e.type
        },
        //确定选择答案
        isAnswer(index){
            if(this.form.type=="radio"){
                this.form.answerList.forEach((item,i)=>{
                    if(i!=index){
                        item.isAnswer = false
                    }else{
                        item.isAnswer = !item.isAnswer
                    }
                })
            }else if(this.form.type=="checkbox"){
                this.form.answerList[index].isAnswer = !this.form.answerList[index].isAnswer
            }
            
        },
        //删除
        delTestItem(){
            this.$emit("delItem",this.index)
        },
        //删除选项
        delSelect(index){
            if(this.form.answerList.length>1){
                this.form.answerList.splice(index,1)
            }
        },
        //添加选项
        addSelect(index){
            if(this.form.answerList.length<=9){
                let item = JSON.parse(JSON.stringify(this.form.answerList[index]))
                item.name = "新增选项"
                item.isAnswer = false 
                item.checked = false
                this.form.answerList.splice(index+1,0,item)
            }
        }
    }
}
</script>
<style lang="less">
.t-i-box{
    margin:10px auto;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(146, 144, 144,0.5);
    border-top:1px #DBDBDB solid;
    border-left:1px #dbdbdb solid;
    padding:10px 15px;
    .t-title-text{
        line-height: 40px;
        display: flex;
        .left-t{
            flex:1;
        }
        .right-t{
            display: flex;
            flex-direction: e;
            text-align: right;
            .any-t{
                cursor: pointer;
                margin:0px 5px;
            }
            .any-t:hover,.del-t:hover{
                color: lightcoral;
            }
            .del-t{
                cursor: pointer;
                margin:0px 5px;
            }
            .focus{
                color: #39F;
            }
        }
    }
    .selectBox{
        margin-top:5px;
        display: flex;
        width:100%;
        padding:0 15px;
        .input{
            flex:1;
        }
        .icon{
            font-size: 26px;
            padding:0 10px;
            cursor: pointer;
        }
        .menu{
            font-size: 20px;
            padding:0 10px;
            display: flex;
            .m-item{
                cursor:pointer;
                margin-left: 10px;
            }
            .m-item:hover{
                color:lightcoral;
            }
        }
        .isanswer{
            min-width: 50px;
        }
        .el-dropdown-link {
            font-size: 12px;
            cursor: pointer;
            color: #409EFF;
        }
        .el-icon-arrow-down {
            font-size: 10px;
            color: #409EFF;
        }
    }
    .m-fillin{
        max-width: 80%;
    }
}
</style>