<template>
    <div class="staff-box" v-loading="loading">
        <!-- 顶部栏 -->
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>学院</el-breadcrumb-item>
            <el-breadcrumb-item>测试题</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="add" round type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list -->
        <div class="test-list">
            <el-empty v-if="list.length<=0" description="还没有内容"></el-empty>
            <el-table
            border
            :data="list"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="名称">
                </el-table-column>
                <el-table-column
                    prop="discription"
                    label="试题描述">
                </el-table-column>
                <el-table-column
                    prop="totalScore"
                    label="试题总分">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="添加日期">
                    <template slot-scope="scope">
                        {{scope.row.time|fomatDay}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="答案解析">
                    <template slot-scope="scope">
                        {{scope.row.showAnalysis?'开启答案解析':'关闭答案解析'}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-edit" @click="handleEdit(scope.row)"></i>
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.id)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia -->
        <el-dialog append-to-body :title="currentTest?'修改':'添加'" :visible.sync="showAdd" lock-scroll>
            <addTest @addSuc="addSuc" :data="currentTest"/>
        </el-dialog>
        <div class="pageNav">
            <el-pagination
                v-model="page"
                hide-on-single-page
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import addTest from '@/components/addTest'
export default {
    components:{
        addTest
    },
    data(){
        return{
            loading:false,
            page:1,
            totalPage:1,
            pageSize:10,
            addRule:checkRules(16),
            form:{
                keys:""
            },
            list:[],
            showAdd:false,
            currentTest:null
        }
    },
    created(){
        this.getTestList()
    },
    filters:{
        fomatDay(time){
            return time.split(" ")[0]
        }
    },
    methods:{
        //页码选择
        currentChange(e){
            //console.log(e)
            this.page = e
            this.getTestList()
        },
        //删除
        handleDelete(id){
            //console.log(id)
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(()=> {
                    let params = {
                        method:"delTest",
                        id:id
                    }
                    this.loading = true 
                    this.$ajax.post(this.API.api,params).then(()=>{
                        //todo
                        this.$message({
                            message: this.CONST.DEL_SUC,
                            type: 'success'
                        });
                        this.getTestList()
                        this.loading = false
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                    })
                }).catch(err=>{
                    this.loading = false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
        },
        //编辑
        handleEdit(data){
            this.currentTest = JSON.parse(JSON.stringify(data))
            console.log(this.currentTest)
            this.showAdd = true
        },
        //获取测试题列表
        getTestList(){
            let parmas ={
                method:'getTestList',
                keys:this.form.keys,
                page:this.page,
                pageSize:this.pageSize
            }
            this.loading = true
            this.$ajax.post(this.API.api,parmas).then(res=>{
                //todo
                console.log(res)
                this.loading = false  
                this.totalPage = res.data.totalPage 
                //this.list = this.list.concat(res.data.list)
                this.list = res.data.list
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //添加
        add(){
            this.currentTest = null
            this.showAdd = true
        },
        //添加成功
        addSuc(id){
            console.log(id)
            this.showAdd=false
        }
    }
}
</script>