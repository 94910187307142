<template>
    <div class="add-test">
        <div class="t-header">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                <el-form-item label="名称" prop="title">
                    <el-input v-model="form.title" placeholder="请输入名称" ></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="discription">
                    <el-input v-model="form.discription"  type="textarea" placeholder="添加测试题描述"
  :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                </el-form-item>
                <el-form-item label="总分" prop="totalScore">
                    <el-input-number v-model="form.totalScore" :min="1" :max="100" label="总分"></el-input-number>
                </el-form-item>
                <el-form-item label="显示解析">
                    <el-switch
                        v-model="form.showAnalysis"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                        </el-switch>
                </el-form-item>
            </el-form>
        </div>
        <el-divider></el-divider>
        <div class="v-title">
            答题目录
        </div>
        <div class="t-body" v-loading="loading">
            <!-- list -->
            <div class="t-list">
                <!-- <el-empty v-if="list.length<=0" description="还没有题目"></el-empty> -->
                <TestItem @delItem="delItem" v-for="(item,index) in list" :item="item" :key="index" :index="index"/>
            </div>
            <!-- add -->
            <!-- <el-divider></el-divider> -->
            <el-popover
            placement="top"
            width="400"
            trigger="hover">
            <div class="menulist">
                <van-grid clickable>
                    <van-grid-item v-for="(item,index) in widgetList" :key="index"
                    :icon="item.icon" :text="item.title" @click="click(index)"/>
                </van-grid>
            </div>
            <div class="btn-box add-item" slot="reference">
                <i class="el-icon-plus"></i>添加题目
            </div>
            </el-popover>
        </div>
        <!-- save -->
             <div class="btn-box">
                        <el-button type="primary" @click="onSubmit('form')">
                        保存</el-button> 
             </div>
    </div>
</template>
<script>
import TestItem from '@/components/TestItem'
import Vue from 'vue';
import { Icon, Grid, GridItem,Cell } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(Icon);
export default {
    components:{
        TestItem
    },
    props:{
        data:{
            type:Object
        }
    },
    watch:{
        data:{
            handler(val){
                if(val){
                    this.form =val
                    if(val.id&&val.id!=''){
                        //获取选项内容
                        this.getTestItemList()
                    }
                }else{
                    this.$refs['form'].resetFields()
                    this.list=[]
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        return{
            loading:false,
            rules:{
                title: [{ required: true, message: "请输入测试题名称", trigger: 'blur' }],
                discription: [{ required: true, message: "请输入测试题说明", trigger: 'blur' }],
                totalScore: [{ required: true, message: "请输入测试题总分", trigger: 'blur' }]
            }
            ,
            type:"",
            title:"",
            form:{
                id:"",
                title:"",
                discription:"",
                totalScore:100,
                showAnalysis:true,
                time:""
            },
            showAdd:false,
            list:[],
            widgetList:[
                {
                    icon:"circle",
                    title:"单选",
                    type:"radio"
                },{
                    icon:"stop-circle-o",
                    title:"多选",
                    type:"checkbox"
                },{
                    icon:"edit",
                    title:"填空",
                    type:"FILLIN"
                }
            ],
            item:{
                title:"",
                type:"",
                analysis:"",
                answer:"",
                data:"",
                answerList:[],
                t_order:0
            }
        }
    },
    methods:{
         onSubmit(formName){
            //console.log(this.list)
            this.$refs[formName].validate((valid) => {
                if(valid){
                    //检查list是否合格
                    this.checklist().then(()=>{
                        //console.log('提交成功')
                        let params = this.form 
                        params.method = "addTest" 
                        params.list = JSON.stringify(this.list)
                        console.log(params.list)
                        this.$ajax.post(this.API.api,params).then(res=>{
                            //console.log(res)
                            this.$refs['form'].resetFields()
                            this.list = []
                            this.$emit("addSuc",res.data.id)
                            this.$message({
                                message: '保存成功！',
                                type: 'success'
                            });
                        }).catch(err=>{
                            //err
                            console.log(err)
                            this.loading = false
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                        })
                    }).catch((res)=>{
                        console.log(res)
                        this.$message({
                            message:"第"+ (res.index +1)+"题存在设置问题，请检查修改！",
                            type: 'warning'
                        });
                    })
                }
            })
        
        },
        //获取测试题选项
        getTestItemList(){
            if(!this.form.id){
                return
            }
            let params = {
                method:"getTestItemList",
                id:this.form.id
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                    this.loading = false  
                    console.log(data,'000') 
                    if(data){
                        data.forEach(item=>{
                            if(item.type!="FILLIN"){
                                console.log(item.answerList,'--')
                                item.answerList = item.answerList&&item.answerList!='null'?JSON.parse(item.answerList):[]
                            }
                        })
                        this.list = data
                    } 
                    console.log(this.list)
               }).catch(err=>{
                 console.log(err)
                 this.loading = false
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                })
        },
        //检查列表
         checklist(){
            let data = {
                checked:this.list.length<=0?false:true,
                index:0
            }
            //console.log(data)
            return new Promise((resolve,reject)=>{
                this.list.forEach((item,index)=>{
                    //console.log(item.type,'--',item.answer)
                    if(item.type=="FILLIN"&&item.answer==''){
                        //填空
                        data.checked = false
                        data.index = index
                    }else if((item.type=="radio"&&item.answerList.length<=0)||(item.type=="checkbox"&&item.answerList.length<=0)){
                        data.checked = false 
                        data.index = index
                        //console.log('000')
                    }
                    //console.log(item.answerList,item.type)
                })
                if(data.checked){
                    resolve(data)
                }else{
                    reject(data)
                }
                
            })
        },
        //删除
        delItem(index){
            this.list.splice(index,1)
        },
        //迪纳吉
        click(index){
            let item = JSON.parse(JSON.stringify(this.item))
            item.type = this.widgetList[index].type 
            item.t_order = this.list.length
            item.title = '第'+(item.t_order + 1 )+'题标题'
            if(item.type!='FILLIN'){
                item.answerList.push(
                    {
                        name:"选项一",
                        type:"text",
                        isAnswer:false,
                        checked:false
                    }
                )
                item.answerList.push(
                    {
                        name:"选项二",
                        type:"text",
                        isAnswer:false,
                        checked:false
                    }
                )
            }
            this.list.push(item)
        }
    }
}
</script>
<style lang="less" scoped>
.btn-box.add-item{
    font-size: 16px;
    cursor: pointer;
}
.add-item:hover{
    color:lightcoral
}
</style>